var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "tagcloud-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "tagcloud-controls",
          staticStyle: { "--num-elements": "20" }
        },
        [
          _vm._l(_vm.blockNum, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tagcloud-control-button",
                style: "--index: " + (index + 1)
              },
              [
                _c("input", {
                  attrs: { name: "tagcloud-control-input", type: "radio" }
                })
              ]
            )
          }),
          _c("div", { staticClass: "tagcloud-rotation" }, [
            _c(
              "ul",
              {
                staticClass: "tagcloud-tags",
                staticStyle: { "--num-elements": "93" },
                attrs: { id: "test" }
              },
              [
                _vm._l(_vm.tagsNum, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "tagcloud-tag",
                      style: "--index: " + (index + 1)
                    },
                    [
                      _c("div", [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(" " + _vm._s(_vm.getName(index)) + " ")
                        ])
                      ])
                    ]
                  )
                }),
                _vm._v(" ` ")
              ],
              2
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }