<template>
  <div class="login-container">
    <el-row>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <earth />
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <div class="loginForm">
          <div class="ring">
            <span style="--clr: #00ff0a"></span>
            <span style="--clr: #ff0057"></span>
            <span style="--clr: #fffd44"></span>
            <el-form
              ref="form"
              class="login-form"
              label-position="left"
              :model="form"
              :rules="rules"
            >
              <div class="title">Welcome !</div>
              <div class="title-tips">
                {{ translateTitle('欢迎来到') }}{{ title }}！
              </div>
              <el-form-item prop="userName" style="margin-top: 40px">
                <el-input
                  v-model.trim="form.userName"
                  v-focus
                  class="userInput"
                  :placeholder="translateTitle('请输入用户名')"
                  tabindex="1"
                  type="text"
                />
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model.trim="form.password"
                  class="userInput"
                  :placeholder="translateTitle('请输入密码')"
                  tabindex="2"
                  :type="passwordType"
                  @keyup.enter.native="handleLogin"
                >
                  <template v-if="passwordType === 'password'" #suffix>
                    <vab-icon
                      class="show-password"
                      icon="eye-off-line"
                      @click="handlePassword"
                    />
                  </template>
                  <template v-else #suffix>
                    <vab-icon
                      class="show-password"
                      icon="eye-line"
                      @click="handlePassword"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-button
                class="login-btn"
                :loading="loading"
                type="primary"
                @click.prevent="handleLogin"
              >
                {{ translateTitle('登录') }}
              </el-button>
            </el-form>
          </div>
        </div>
      </el-col>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { isPassword } from '@/utils/validate'
  import { mapActions, mapGetters } from 'vuex'
  import earth from './components/earth.vue'

  export default {
    name: 'Login',
    components: {
      earth,
    },
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(this.timer)
      next()
    },
    data() {
      const validateUsername = (rule, value, callback) => {
        if ('' === value)
          callback(new Error(this.translateTitle('用户名不能为空')))
        else callback()
      }
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value))
          callback(new Error(this.translateTitle('密码不能少于6位')))
        else callback()
      }
      const validateValue = (rule, value, callback) => {
        if ('' === value) callback(new Error(this.translateTitle('请选择公司')))
        else callback()
      }
      return {
        options: [],
        form: {
          userName: '',
          password: '',
          // companyGuid: '',
          // verificationCode: '',
        },
        rules: {
          userName: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: 'password',
        redirect: '/',
        timer: 0,
        codeUrl: 'https://www.oschina.net/action/user/captcha',
        previewText: '',
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
      }),
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const res = await findAll()
        this.options = res.data
      },
      //全局变量公司ID
      selectClick(val) {
        let doctor = this.options.filter((doctor) => {
          return doctor.guid == val
        })
        this.company.companyId = doctor[0].id
      },
      ...mapActions({
        login: 'user/login',
      }),
      translateTitle,
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleRoute() {
        return this.redirect
      },
      handleLogin() {
        this.$refs.form.validate(async (valid) => {
          if (valid)
            try {
              this.loading = true
              await this.login(this.form)
              // const res = await login(this.form)
              // localStorage.setItem('needUpdatePw', res.data.needUpdatePw)
              // localStorage.setItem('userName', res.data.loginName)
              await this.$router.push(this.handleRoute())
            } finally {
              this.loading = false
            }
        })
      },
      // changeCode() {
      //   this.codeUrl = `https://www.oschina.net/action/user/captcha?timestamp=${new Date().getTime()}`
      // },
    },
  }
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    background: url('~@/assets/login_images/background.png') center center fixed
      no-repeat;
    background-size: cover;
    overflow: hidden;
  }
  .loginForm {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ring {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ring span {
    position: absolute;
    inset: 0;
    border: 2px solid #fff;
    transition: 0.5s;
  }
  .ring span:nth-child(1) {
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    animation: animate 6s linear infinite;
  }
  .ring span:nth-child(2) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate 4s linear infinite;
  }
  .ring span:nth-child(3) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate2 10s linear infinite;
  }
  .ring:hover span {
    border: 6px solid var(--clr);
    filter: drop-shadow(0 0 20px var(--clr));
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate2 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .login-form {
    position: relative;
    overflow: hidden;

    .title {
      font-size: 48px;
      font-weight: 500;
      color: $base-color-white;
    }

    .title-tips {
      margin-top: 29px;
      font-size: 24px;
      font-weight: 400;
      color: $base-color-white;
    }

    .login-btn {
      width: 100%;
      background: #0078ff;
      background: linear-gradient(45deg, #ff357a, #fff172);
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      .forget-passwordword {
        width: 100%;
        margin-top: 40px;
        text-align: left;

        .forget-password {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(92, 102, 240, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: $base-color-blue;
        text-align: center;
      }
    }

    i {
      position: absolute;
      top: 8px;
      left: 5px;
      z-index: $base-z-index;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      top: 0px;
      left: -35px;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          padding: 12px 20px;
          background: transparent !important;
          border: 2px solid #fff;
          border-radius: 40px;
          font-size: 1.2em;
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        input::placeholder {
          color: rgba(255, 255, 255, 0.75);
        }
      }

      .code {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
        border-radius: $base-border-radius;
      }
      .el-form-item__error {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
</style>
