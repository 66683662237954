<template>
  <div class="container">
    <div class="tagcloud-wrapper">
      <div class="tagcloud-controls" style="--num-elements: 20">
        <div
          v-for="(item, index) in blockNum"
          :key="index"
          class="tagcloud-control-button"
          :style="`--index: ${index + 1}`"
        >
          <input name="tagcloud-control-input" type="radio" />
        </div>
        <div class="tagcloud-rotation">
          <ul id="test" class="tagcloud-tags" style="--num-elements: 93">
            <li
              v-for="(item, index) in tagsNum"
              :key="index"
              class="tagcloud-tag"
              :style="`--index: ${index + 1}`"
            >
              <div>
                <a href="#">
                  {{ getName(index) }}
                </a>
              </div>
            </li>
            `
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Login',

    data() {
      return {
        blockNum: new Array(20).fill(0),
        tagsNum: new Array(92).fill(0),
        listName: [
          'Leon',
          'Milo',
          'Rosa',
          'Rei',
          'Xena',
          'Vamos',
          'Candy',
          'Delia',
          'Loya',
          'Lucia',
          'Summer',
          '加油！',
          'Faire le plein！',
          '頑張って！',
          'Давай.！',
          '파이팅！',
          'DIDADI',
        ],
      }
    },
    methods: {
      getName(index) {
        return this.listName[(index + 1) % this.listName.length]
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import url(//fonts.googleapis.com/css?family=Open+Sans);
  .container {
    --tagcloud-transition-user-duration: 1250ms;
    --tagcloud-transition-user-ease: ease-in-out;
    --tagcloud-transition-duration: 250ms;
    --tagcloud-transition-ease: ease-out;
    --tagcloud-bg-color-rgb: #e94102;
    --tagcloud-animation-duration: 25s;
    --tagcloud-animation-direction: normal;
    --tagcloud-animation-play-state: running;
    --tagcloud-diameter: 32rem;
    --tagcloud-start-rotation: 54;
    --tagcloud-controls-diameter: 256rem;
    --tagcloud-control-bg-color: transparent;
    --tagcloud-control-bg-hover-color: transparent;
    --tagcloud-control-bg-checked-color: transparent;
    --tag-diameter: 5.5rem;
    --tag-font-color-rgb: 255, 255, 255;
    --tag-font-family: 'Open Sans', sans-serif;
    --tag-font-size: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;

    *,
    *::before,
    *::after {
      margin: 0;
      padding: 0;
      border: 0;
      box-sizing: border-box;
    }

    *:focus {
      outline: none;
    }
  }

  /*
tagcloud
*/
  .tagcloud-wrapper {
    --_control-diamater: var(--tagcloud-controls-diameter);
    --_control-radius: calc(var(--_control-diamater) / 2);
    --_diameter: var(--tagcloud-diameter);
    --_radius: calc(calc(var(--_diameter) / 2) - calc(var(--tag-diameter) / 2));
    width: var(--control-diameter);
    aspect-ratio: 1 / 1;
    font-family: var(--tag-font-family);
    font-size: var(--tag-font-size);
  }

  @media only screen and (max-width: 48rem) {
    .tagcloud-wrapper {
      --_control-diamater: calc(var(--tagcloud-controls-diameter) * 0.5);
      --_diameter: calc(var(--tagcloud-diameter) * 0.88);
    }
  }
  @media only screen and (max-width: 32rem) {
    .tagcloud-wrapper {
      --_diameter: calc(var(--tagcloud-diameter) * 0.75);
    }
  }

  .tagcloud-wrapper:has(.tagcloud-rotation:hover) {
    --tagcloud-animation-play-state: paused;
  }

  .tagcloud-wrapper .tagcloud-tags {
    position: absolute;
    width: var(--_diameter);
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    list-style-type: none;
    position: relative;
    transform-style: inherit;
    animation: tagcloud-rotation var(--tagcloud-animation-duration)
      var(--tagcloud-animation-direction) linear infinite
      var(--tagcloud-animation-play-state);
  }

  @keyframes tagcloud-rotation {
    from {
      transform: translate(-50%, -50%) rotateX(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotateX(360deg);
    }
  }

  .tagcloud-wrapper
    .tagcloud-tags:has(.tagcloud-tag div a:hover)
    .tagcloud-tag:not(:has(div a:hover)) {
    opacity: 0.25;
  }

  .tagcloud-wrapper .tagcloud-tag {
    --_phi: acos(calc(-1 + (2 * var(--index)) / var(--num-elements)));
    // eslint-disable-next-line prettier/prettier
    --_theta1: calc(var(--num-elements) * 3.141592653589793);
    --_theta: calc(sqrt(var(--_theta1)) * var(--_phi));
    --_x: calc(cos(var(--_theta)) * sin(var(--_phi)));
    --_y: calc(sin(var(--_theta)) * sin(var(--_phi)));
    --_z: calc(cos(var(--_phi)));
    --_vector-length: sqrt(
      var(--_x) * var(--_x) + var(--_y) * var(--_y) + var(--_z) * var(--_z)
    );
    --_normalized-x: calc(var(--_x) / var(--_vector-length));
    --_normalized-y: calc(var(--_y) / var(--_vector-length));
    --_normalized-z: calc(var(--_z) / var(--_vector-length));
    --_scaled-x: calc(var(--_normalized-x) * var(--_radius));
    --_scaled-y: calc(var(--_normalized-y) * var(--_radius));
    --_scaled-z: calc(var(--_normalized-z) * var(--_radius));
    --_final-x: calc(var(--_scaled-x) + var(--_radius));
    --_final-y: calc(var(--_scaled-y) + var(--_radius));
    --_final-z: var(--_scaled-z);
    pointer-events: none;
    width: var(--tag-diameter);
    height: var(--tag-diameter);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: opacity var(--tagcloud-transition-duration)
      var(--tagcloud-transition-ease);
    transform: translate3d(var(--_final-x), var(--_final-y), var(--_final-z));
    animation: tagcloud-tag-rotation var(--tagcloud-animation-duration)
      var(--tagcloud-animation-direction) linear infinite
      var(--tagcloud-animation-play-state);
  }

  @keyframes tagcloud-tag-rotation {
    from {
      transform: translate3d(var(--_final-x), var(--_final-y), var(--_final-z))
        rotateX(360deg);
    }
    to {
      transform: translate3d(var(--_final-x), var(--_final-y), var(--_final-z))
        rotateX(0deg);
    }
  }

  .tagcloud-wrapper .tagcloud-tag div {
    transform: rotateZ(calc(var(--_current-rotation) * -1deg));
    transition: transform var(--tagcloud-transition-user-duration)
      var(--tagcloud-transition-user-ease);
  }

  .tagcloud-wrapper .tagcloud-tag div a {
    pointer-events: initial;
    color: rgb(var(--tag-font-color-rgb));
    text-decoration: none;
    text-shadow: 1px 1px 1px rgb(var(--tagcloud-bg-color-rgb)),
      1px -1px 1px rgb(var(--tagcloud-bg-color-rgb)),
      -1px 1px 1px rgb(var(--tagcloud-bg-color-rgb)),
      -1px -1px 1px rgb(var(--tagcloud-bg-color-rgb)),
      0 0 1rem rgb(var(--tagcloud-bg-color-rgb));
  }

  .tagcloud-wrapper .tagcloud-controls {
    width: var(--_control-diamater);
    aspect-ratio: 1 / 1;
    position: relative;
    --_current-rotation: var(--tagcloud-start-rotation);
    transform-style: inherit;
  }

  .tagcloud-wrapper .tagcloud-controls .tagcloud-control-button {
    --_width: var(--_control-radius);
    --_height: calc(
      var(--_control-diamater) * 3.141592653589793 / var(--num-elements) + 1px
    );
    --_theta-start: 0;
    --_theta-length: calc(2 * 3.141592653589793);
    --_segment: calc(
      var(--_theta-start) + var(--index) / var(--num-elements) *
        var(--_theta-length)
    );
    --_x: calc(var(--_control-radius) * cos(var(--_segment)));
    --_y: calc(
      var(--_control-radius) * sin(var(--_segment)) + var(--_control-radius) -
        var(--_height) / 2
    );
    --_rotation: calc(var(--index) / var(--num-elements) * 360deg);
    position: absolute;
    left: var(--_x);
    top: var(--_y);
    width: var(--_width);
    height: var(--_height);
    clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
    transform-origin: right center;
    transform: rotate(var(--_rotation));
    transition: background-color var(--tagcloud-transition-duration)
      var(--tagcloud-transition-ease);
    background-color: var(--tagcloud-control-bg-color);
  }

  .tagcloud-wrapper .tagcloud-controls .tagcloud-control-button input {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .tagcloud-wrapper
    .tagcloud-controls
    .tagcloud-control-button:has(input:checked) {
    background-color: var(--tagcloud-control-bg-checked-color);
  }
  .tagcloud-wrapper
    .tagcloud-controls
    .tagcloud-control-button:has(input:hover) {
    background-color: var(--tagcloud-control-bg-hover-color);
  }

  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(1)
      input:checked) {
    --_current-rotation: 108;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(2)
      input:checked) {
    --_current-rotation: 126;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(3)
      input:checked) {
    --_current-rotation: 144;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(4)
      input:checked) {
    --_current-rotation: 162;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(5)
      input:checked) {
    --_current-rotation: 180;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(6)
      input:checked) {
    --_current-rotation: 198;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(7)
      input:checked) {
    --_current-rotation: 216;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(8)
      input:checked) {
    --_current-rotation: 234;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(9)
      input:checked) {
    --_current-rotation: 252;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(10)
      input:checked) {
    --_current-rotation: 270;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(11)
      input:checked) {
    --_current-rotation: 288;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(12)
      input:checked) {
    --_current-rotation: 306;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(13)
      input:checked) {
    --_current-rotation: 324;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(14)
      input:checked) {
    --_current-rotation: 342;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(15)
      input:checked) {
    --_current-rotation: 0;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(16)
      input:checked) {
    --_current-rotation: 18;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(17)
      input:checked) {
    --_current-rotation: 36;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(18)
      input:checked) {
    --_current-rotation: 54;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(19)
      input:checked) {
    --_current-rotation: 72;
  }
  .tagcloud-wrapper
    .tagcloud-controls:has(.tagcloud-control-button:nth-child(20)
      input:checked) {
    --_current-rotation: 90;
  }

  .tagcloud-wrapper
    .tagcloud-controls
    .tagcloud-control-button:has(input:checked)
    ~ .tagcloud-rotation {
    transform: translate(-50%, -50%)
      rotate(calc(var(--_current-rotation) * 1deg));
  }

  .tagcloud-wrapper
    .tagcloud-controls
    .tagcloud-control-button:has(input:checked)
    ~ .tagcloud-rotation
    .tagcloud-tags
    .tagcloud-tag
    div {
    transform: rotateZ(calc(var(--_current-rotation) * -1deg));
  }

  .tagcloud-wrapper .tagcloud-controls .tagcloud-rotation {
    position: absolute;
    width: var(--_diameter);
    aspect-ratio: 1 / 1;
    perspective: calc(var(--_diameter) * 2);
    transform-style: preserve-3d;
    left: 50%;
    top: 50%;
    background: radial-gradient(
      rgba(var(--tagcloud-bg-color-rgb), 0.75) 15%,
      rgba(var(--tagcloud-bg-color-rgb), 0) calc(75% - var(--tag-diameter))
    );
    border-radius: 50%;
    transform: translate(-50%, -50%)
      rotate(calc(var(--_current-rotation) * 1deg));
    transition: transform var(--tagcloud-transition-user-duration)
      var(--tagcloud-transition-user-ease);
  }
</style>
